import { createSlice } from "@reduxjs/toolkit";
import { AnyAction, Dispatch } from "redux";
import { inject } from 'types';
import { CommonActions, addAction } from './../common/Actions';
import {WalletAuthClient} from './../common/WalletAuthClient';
import { History } from 'history';
import { setAllThemes, setToLS, removeFromLS, getFromLS } from "./../theme/storageUtils/storage";
import { useTheme as newThemeInitialization, themeMapper } from "./../theme/useTheme";
import * as defaultTheme from "./../theme/schema.json";

export interface HomeContentProps {
    initialized: boolean,
    connected: boolean,
    groupId: string,
    network:string,
    email:string,
    initializeError: string,
    addresses: any[],
    currencies: string[]
}


export async function onAppLoad(dispatch:Dispatch<AnyAction>, history: History) {
    try {
        dispatch(addAction(CommonActions.WAITING, { source: 'dashboard' }));
        const client = inject<WalletAuthClient>(WalletAuthClient);
        let groupId = 'frm';
        let groupInfo = await client.loadGroupInfo(dispatch, groupId!);
        console.log(groupInfo);
        if(groupInfo){
            const mappedBridgeTheme = themeMapper(groupInfo.themeVariables)
            if (groupInfo) {
                setToLS('all-themes', { ...mappedBridgeTheme });
                setToLS(`${groupId}-all-themes`, { ...mappedBridgeTheme });
                //@ts-ignore
                setToLS('theme', { ...mappedBridgeTheme.data.light });
                //@ts-ignore
                setToLS(`${groupId}-theme`, { ...mappedBridgeTheme.data.light })
            } else {
                setAllThemes("all-themes", defaultTheme);
            }
        }
       
    } catch (error) {
        console.error('Error initializing', error);
        dispatch(Actions.initializeError({ initError: 'Network Error' }));
        return;
    } finally {
        dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
    }
}

export const HomeSlice = createSlice({
    name: 'dashboard',
    initialState: {
        initialized: false,
        connected: false,
        groupId: '',
        network: '',
        initializeError: '',
        email:''
    } as HomeContentProps,
    reducers: {
        initializeError: (state, action) => {
            const { initError } = action.payload;
            state.initializeError = initError;
        },
        initializing: (state, action) => {
            state.initializeError = '';
        },
        emailChanged: (state,action) => {
            state.email = action.payload.value
        }
    },
    extraReducers:(builder) => {
        builder.addCase("webAuthSlice/addressInfoFetched", (state,action) => {
            //@ts-ignore
            state.email = action.payload.email
        });
    }
});

export const Actions = HomeSlice.actions;
