import React from 'react';
import { StoreBuilder } from 'common-containers';
import { dataReducer, uiReducer, userReducer } from './common/Reducer';
import { BrowserRouter as Router } from 'react-router-dom';
import { WalletAuthModule } from './common/WalletAuthModule';
import { Home } from './pages/home';
import { ToastProvider } from "react-toast-notifications";

import './app.scss';
import "./assets/css/icons.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/styles.scss";

const _module = new WalletAuthModule();
const BASE_URL = 'https://an54zzyt9h.execute-api.ap-south-1.amazonaws.com/default/VerifyAddressAuth';
const store = StoreBuilder.build(userReducer, dataReducer, uiReducer, _module, BASE_URL);

function App() {
  return (
    <StoreBuilder.Provider store={store}>
      <ToastProvider>
        <Router>
          <div className="App">
            <Home/>
          </div>
        </Router>
      </ToastProvider>
    </StoreBuilder.Provider>
  );
}

export default App;
