import { JsonRpcRequest, Networks, ValidationUtils } from 'ferrum-plumbing';
import { ApiClient } from "common-containers";
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { Connect, UnifyreExtensionWeb3Client } from 'unifyre-extension-web3-retrofit';
import { logError, GroupInfo } from "types";
import { addAction,CommonActions } from './Actions';

export class WalletAuthClient {
    constructor(
		private api: ApiClient,
		private connect: Connect,
	) {}

    __name__() { return 'WalletAuthClient'; }

    async loadGroupInfo(dispatch: Dispatch<AnyAction>, groupId: string): Promise<GroupInfo|undefined> {
        try {
            ValidationUtils.isTrue(!!groupId, '"groupId" must be provided');
            dispatch(addAction(CommonActions.WAITING, { source: 'loadGroupInfo' }));
            let groupInfo = (await this.api.api({
                command: 'getGroupInfo', data: {groupId},
                params: []}as JsonRpcRequest)) as any;
            if (!groupInfo) {
                return undefined;
            } 
            console.log(groupInfo)
            dispatch(addAction(CommonActions.GROUP_INFO_LOADED, groupInfo));
            return groupInfo;
        } catch (e) {
            logError('Error loading group info', e as Error);
            return;
        } finally {
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
        }
    }

    async authenticateAddress(address: string){
		ValidationUtils.isTrue(!!address, '"signature" must be provided');
		const res = await this.api.api({
			command: 'authenticateAddress',data : { address },params: []
		} as JsonRpcRequest);
		return  res;
	}

    async sendOtp(dispatch: Dispatch<AnyAction>,address:string,email:string){
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'loadGroupInfo' }));
            let response = (await this.api.api({
                command: 'sendEmailVerificationOtp', data: {address,email},
                params: []}as JsonRpcRequest)) as any;
            return response;
        } catch (e) {
            logError('Error loading group info', e as Error);
            return;
        } finally{
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
        }
    }

    async verifyOtp(dispatch: Dispatch<AnyAction>,address:string,email:string,otp:string){
        try {
            dispatch(addAction(CommonActions.WAITING, { source: 'loadGroupInfo' }));
            let response = (await this.api.api({
                command: 'verifyOtp', data: {address,email,otp},
                params: []}as JsonRpcRequest)) as any;
            return response;
        } catch (e) {
            logError('Error loading group info', e as Error);
            return;
        } finally{
            dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
        }
    }
}
