import React,{ useState,useEffect }  from 'react';
import { Card } from "react-bootstrap";
import {
    TextInput,RegularBtn
    //@ts-ignore
  } from "component-library";
import { useDispatch, useSelector } from 'react-redux';
import {WalletAuthAppState} from './../../common/Reducer';
import {Actions, HomeContentProps,onAppLoad} from './../../pages/HomeSlice';
import { ApiClient, AppAccountState,walletAuthState } from 'common-containers';
import { ConnectMetaMask } from './../../components/ValidateUserBtn';
import OtpInput from 'react-otp-input';
import { AnyAction, Dispatch } from "redux";
import { CommonActions, addAction } from './../../common/Actions';
import { inject } from 'types';
import {WalletAuthClient} from './../../common/WalletAuthClient';
import { useToasts } from 'react-toast-notifications';

function stateToProps(appState: WalletAuthAppState, userAccounts: AppAccountState): HomeContentProps {
    const state = (appState.ui.home || {});
    const addr = userAccounts?.user?.accountGroups[0]?.addresses || {};
    const address = addr[0] || {};
    const curs = appState.data.state.groupInfo?.bridgeCurrencies || [];
    return {
        ...state,
        initializeError: state.initializeError,
        network: address.network,
        addresses: addr,
        currencies: curs,
    } as HomeContentProps;
}

async function sendOtp(dispatch:Dispatch<AnyAction>,
    address:string,
    email:string,
    success:(v:string)=>void,
    failure:(v:string)=>void,
    showOtp: (b:boolean) => void
){
    try {
        dispatch(addAction(CommonActions.WAITING, { source: 'dashboard' }));
        const client = inject<WalletAuthClient>(WalletAuthClient);
        const response = await client.sendOtp(dispatch,address,email);
        console.log(response,'response')
        if(response.status === "Success"){
            success('Email notification sent');
            showOtp(true)
        }else{
            failure('Email notification failed')
            dispatch(Actions.initializeError({ initError: 'Network Error' }));
        }
    } catch (error) {
        console.error('Error initializing', error);
        dispatch(Actions.initializeError({ initError: 'Network Error' }));

    } finally {
        dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
    }
}

async function verifyOtp(
    dispatch:Dispatch<AnyAction>,address:string,email:string,otp:string,
    success:(v:string)=>void,
    failure: (v:string)=>void,
    showOtp: (v:boolean)=>void,
    setOtp: (v:string) => void
){
    try {
        dispatch(addAction(CommonActions.WAITING, { source: 'dashboard' }));
        const client = inject<WalletAuthClient>(WalletAuthClient);
        const response = await client.verifyOtp(dispatch,address,email,otp);
        if(response.status) {
            success('Address Email successfully saved.')
            showOtp(false)
            setOtp('')
        }else{
            failure('Invalid Otp entered')
        }
    } catch (error) {
        console.error('Error initializing', error);
        dispatch(Actions.initializeError({ initError: 'Network Error' }));

    } finally {
        dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
    }
  
}

async function checkAuth(
    dispatch:Dispatch<AnyAction>,
    address:string
    ){
    try{
        dispatch(addAction(CommonActions.WAITING, { source: 'dashboard' }));
        const client = inject<WalletAuthClient>(WalletAuthClient);
        const res = await client.authenticateAddress(address)
    }catch (error) {
        console.error('Error initializing', error);
        dispatch(Actions.initializeError({ initError: 'Network Error' }));

    } finally {
        dispatch(addAction(CommonActions.WAITING_DONE, { source: 'loadGroupInfo' }));
    }
}

export function WalletAuth(){
    const dispatch = useDispatch()
    const userAccounts = useSelector<WalletAuthAppState, AppAccountState>(state => state.connection.account);
    const authInfo = useSelector<WalletAuthAppState,walletAuthState>(state => state.connection.walletAuth)
    const stateData = useSelector<WalletAuthAppState, HomeContentProps>(appS => stateToProps(appS, userAccounts));
    const [showOtp,setShowOtp] = useState(false);
    const [otp,setOtp] = useState('');
    const { addToast } = useToasts();

    const onMessage = async (v: string) => {
        addToast(v, { appearance: 'error', autoDismiss: true })
    };

    const onSuccessMessage = async (v: string) => {
        addToast(v, { appearance: 'success', autoDismiss: true })
    };

    return(
        <div className="centered-body liquidity1" style={{ width: '50%', margin: '50px auto'}} >
            <Card className="text-center">
                <div>
                    <div className="body-not-centered swap liquidity">
                        <small className="text-vary-color mb-5 head">
                                Address Info Manager
                                <hr className="mini-underline"></hr>
                        </small>
                    </div>
                    <p></p>
                    <p></p>
                    <div>
                        <div className="pad-main-body">
                            <div className="text-sec text-left">
                                Current Address
                            </div>
                            <TextInput
                                onChange={(v: any) =>{}}
                                style={{ fontSize: "22px" }}
                                value={(stateData.addresses||[])[0]?.address||''}
                            />
                        </div> 
                        {!authInfo.address && <ConnectMetaMask/>}
                    </div>
                    {
                        authInfo.address &&
                        <div>
                            <div className="pad-main-body">
                                <div className="text-sec text-left">
                                    Email Address
                                </div>
                                <TextInput
                                    onChange={(v: any) =>dispatch(Actions.emailChanged({value:v.target.value}))}
                                    style={{ fontSize: "22px" }}
                                    value={stateData.email}
                                />
                            </div> 
                            {
                                !showOtp && <RegularBtn 
                                text={!authInfo.email ? 'Verify Address OwnerShip' : 'Update Address Email'} onClick={()=>sendOtp(
                                    dispatch,
                                    authInfo.address,
                                    stateData.email,
                                    onSuccessMessage,
                                    onMessage,
                                    setShowOtp,
                                )}/>
                            }
                        </div>
                    }
                    {
                        showOtp && <>
                            <div className="pad-main-body">
                                <div className="text-sec text-left">
                                    Enter OTP Sent to Email
                                </div>
                                <OtpInput
                                    containerStyle={{justifyContent:"center",margin: '20px auto'}}
                                    inputStyle={{width:"4em",height:"4em",borderRadius:"20%"}}
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    separator={<span>---</span>}
                                />
                            </div>
                            <RegularBtn text={'Validate Otp'} onClick={()=>verifyOtp(
                                dispatch,authInfo.address,stateData.email,otp,
                                onSuccessMessage,onMessage,setShowOtp,setOtp
                            )}/>
                        </>
                    }
                    <div>
                    </div>
                  
                   
                </div>
            </Card>
        </div>
    )
}