import { combineReducers } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { AppInitializingState,AppAccountState,AppState } from 'common-containers';
import {HomeContentProps} from './../pages/HomeSlice';
import { GroupInfo } from 'types';
import { HomeSlice } from './../pages/HomeSlice';

export const CommonActions = {
    WAITING: 'WAITING',
    WAITING_DONE: 'WAITING_DONE',
    CONTINUATION_DATA_RECEIVED: 'CONTINUATION_DATA_RECEIVED',
    CONTINUATION_DATA_FAILED: 'CONTINUATION_DATA_FAILED',
    GROUP_INFO_LOADED: 'GROUP_INFO_LOADED',
    FILTERED_TOKEN_LIST_UPDATED: 'FILTERED_TOKEN_LIST_UPDATED',
    ERROR_OCCURED: 'ERROR_OCCURED',
    RESET_ERROR: 'RESET_ERROR'
};

export interface AppUiState {
    home: HomeContentProps
};

export interface AppUserState {
    AppAccountState: AppAccountState
}

export interface AppGlobalState extends AppInitializingState {
    groupInfo: GroupInfo
}

export type WalletAuthAppState = AppState<AppUserState, AppGlobalState, AppUiState>;

export const uiReducer = combineReducers({
    home: HomeSlice.reducer
});

export function userReducer(state = { }, action: AnyAction) {
	switch (action.type) {
		default:
			return state;
	}
}

function clientReducer(state:any, action: AnyAction) {
	switch (action.type) {
		default:
			return state;
	}
}

export function dataReducer(state = {
		waiting: false,
		initialized: false,
        groupInfo: {} as any
    },
	action: AnyAction) {
	switch (action.type) {
        case CommonActions.WAITING:
            return { ...state,waiting: true,error: '' };
        case CommonActions.GROUP_INFO_LOADED:
            return { ...state, groupInfo: action.payload };
        default:
			return clientReducer(state!, action);
	}
}