import React from 'react';
import { Web3AuthWrapper } from 'common-containers';
import { Button } from "react-bootstrap";
//@ts-ignore
import {RegularBtn} from 'component-library';

export function ButtonView (props:any){
    return (
        <RegularBtn text={'Validate Address'} {...props}/>
    )
}
export const ConnectMetaMask = () => {
    

    return(
        <Web3AuthWrapper
            View={ButtonView}
            
        />
    )
}