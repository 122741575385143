import { Container, Module } from 'ferrum-plumbing';
import { ApiClient } from 'common-containers';
import { WalletAuthClient } from './WalletAuthClient';
import { Connect } from 'unifyre-extension-web3-retrofit';

export class WalletAuthModule implements Module {
    private configured: boolean = false;
    async configAsync(c: Container): Promise<void> {
        if (this.configured) { return; }
        try {
            c.register(WalletAuthClient,
							c => new WalletAuthClient(c.get(ApiClient), c.get(Connect)));
        } finally {
            this.configured = true;
        }
    }
}