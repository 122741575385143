import React, { useEffect, useState, useContext } from 'react';
// @ts-ignore
import {Page,Header,CnctButton } from 'component-library';
import { useDispatch, useSelector } from 'react-redux';
import {WalletAuthAppState} from './../common/Reducer';
import {HomeContentProps,onAppLoad} from './HomeSlice';
import { AppAccountState, connectSlice } from 'common-containers';
import { useHistory } from 'react-router';
import { ThemeProvider } from "styled-components";
import { useTheme as newThemeInitialization, themeMapper } from "./../theme/useTheme";
import { GlobalStyles } from "./../theme/GlobalStyles";
import { ConnectButtonWapper } from 'common-containers';
import { WalletAuth } from './../containers/WalletAuth';

function stateToProps(appState: WalletAuthAppState, userAccounts: AppAccountState): HomeContentProps {
    const state = (appState.ui.home || {});
    const addr = userAccounts?.user?.accountGroups[0]?.addresses || {};
    const address = addr[0] || {};
    const curs = appState.data.state.groupInfo?.bridgeCurrencies || [];
    return {
        ...state,
        initializeError: state.initializeError,
        network: address.network,
        addresses: addr,
        currencies: curs,
        email: appState.connection.walletAuth.email
    };
}


export function Home() {
    const dispatch = useDispatch();
    const history = useHistory();
    const userAccounts = useSelector<WalletAuthAppState, AppAccountState>(state => state.connection.account);
    const groupInfoData = useSelector<WalletAuthAppState, any>(appS => appS.data.state.groupInfo);
    const appInitialized = useSelector<WalletAuthAppState, any>(appS => appS.data.init.initialized);
    const initError = useSelector<WalletAuthAppState, string | undefined>(state => state.data.init.initError);
    const stateData = useSelector<WalletAuthAppState, HomeContentProps>(appS => stateToProps(appS, userAccounts));
    let { theme, setTheme, loadTheme } = newThemeInitialization(groupInfoData.groupId ? groupInfoData.groupId : null);
    const [selectedTheme, setSelectedTheme] = useState(theme);
    const ConBot = <ConnectButtonWapper View={CnctButton} />

    const appLoad = async () => {
        await onAppLoad(dispatch, history).catch(console.error)
    }

    useEffect(() => {
        if (groupInfoData.groupId) {
            const theme = loadTheme();
            setSelectedTheme(theme)
        }
    }, [groupInfoData.groupId]);

    useEffect(() => {
        if (appInitialized) {
            appLoad()
        }
    }, [appInitialized]);

    if (appInitialized && !stateData.initializeError) {
        console.log(selectedTheme,'selectedTheme')
        return (
            <ThemeProvider theme={selectedTheme}>
                <GlobalStyles/>
                <>
                    <Header
                        ConnectButton={ConBot}
                        WithdrawlsButton={()=>{}}
                        SwitchNetworkButton={()=>{}}
                        ThemeSelector={() => {}}
                        logo={groupInfoData.themeVariables?.mainLogo}
                        altText={groupInfoData.projectTitle}
                    />
                   <WalletAuth/>
                </>
            </ThemeProvider>
        )
    }
    const fatalErrorComp = (
        (!!initError || !!stateData.initializeError) ? (
            <>
                <Page>
                    <h3>Error loading the application</h3><br />
                    <p>{initError || stateData.initializeError}</p>
                </Page>
            </>
        ) : (
            <Page>
                <h3>Connecting...</h3><br />
            </Page>
        )
    )

    return (
        <>
            <Page>
                {fatalErrorComp}
            </Page>
        </>
    );
}